<template>
    <b-container id="settings" fluid="md">
        <div v-if="!error">
            <h1 class="text-left mb-4 pb-0" v-html="$t(tKey + 'header')" />
            <b-tabs
                vertical
                nav-class="bg-dark text-light rounded"
                active-nav-item-class="bg-custom font-weight-bold"
                nav-wrapper-class="w-25"
                @activate-tab="resetValue"
                @input="setCurrentTab"
            >
                <b-tab 
                    v-for="t in tabs"
                    :key="t.key"
                >
                    <template #title>
                        <b-row class="text-white align-items-center">
                            <b-col md="1">
                                <font-awesome-icon :icon="t.icon" />
                            </b-col>
                            <b-col class="px-md-4 px-sm-0">
                                <span>{{ $t(tKey + t.key + '.title') }}</span>
                            </b-col>
                            <b-col md="1">
                                <font-awesome-icon icon="angle-right" />
                            </b-col>
                        </b-row>
                    </template>
                    <b-card no-body>
                        <b-card-header class="bg-custom text-light">
                            <h3 class="mb-0 pb-0 text-left" v-html="$t(tKey + t.key + '.title')" />
                        </b-card-header>
                        <b-card-body>
                            <component v-if="settings[t.key]" 
                                :is="`${t.key}Settings`" 
                                :settings="settings[t.key]" 
                                :ref="t.key"
                                @update="updateWrapper"
                                @generateTwoFa="showTwoFa"
                                @checkAuthCode="checkAuthCode"
                            />
                            <component v-else
                                :is="`${t.key}Settings`" 
                                :ref="t.key"
                                @updatePsw="attemptPasswordChange"
                            />
                        </b-card-body>
                    </b-card>
                </b-tab>
            </b-tabs>
        </div>
        <div v-else class="mt-4 text-center text-muted display-2">
            <font-awesome-icon class="mb-3" icon="frown" />
            <h2 v-html="$t(tKey + 'error')" />
        </div>
        <b-modal id="success-modal" ref="success-modal">{{ $t("general.pw_success_message") }}
              <template #modal-footer>
                <b-button size="sm" variant="success" @click="okSuccess">
                  {{$t("general.ok_button")}}
                </b-button>
              </template>
        </b-modal>
        <b-modal id="fail-modal" ref="fail-modal">{{ $t("general.pw_error_message") }}
              <template #modal-footer>
                <b-button size="sm" variant="danger" @click="okFail">
                  {{$t("general.ok_button")}}
                </b-button>
              </template>
        </b-modal>
    </b-container>
</template>

<script>
import AuthMixin from '@/mixins/api/auth'
import ProfileSettings from '@/components/settings/ProfileSettings'
import AccountSettings from '@/components/settings/AccountSettings'
import SecuritySettings from '@/components/settings/SecuritySettings'
export default {
    name: 'Settings',
    components: {
        ProfileSettings,
        AccountSettings,
        SecuritySettings,
    },
    mixins: [AuthMixin],
    data() {
        return {
            tKey: 'settings.',
            currentTab: 0,
            settings: null,
        }
    },
    async created()  {
        this.settings = await this.loadSettings()
    },
    computed: {
        tabs() {
            const tabs = [
                {
                    key: 'Profile',
                    icon: 'user-alt',
                },
                {
                    key: 'Account',
                    icon: 'at',
                },
                {
                    key: 'Security',
                    icon: 'key',
                },
            ]
            return tabs
        },
    },
    methods: {
        resetValue(newTab) {
            try {
                this.$refs[this.tabs[newTab].key][0].$refs.form.reset()
            } catch(e) {
                return
            }
        },
        setCurrentTab(tabIndex) {
            this.currentTab = tabIndex
        },
        async updateWrapper(key, form) {
            this.settings = await this.updateSettings(key, form)
        },
        async showTwoFa() {
            await this.callAuthenticatorSetup().then((data) => {
                this.$refs.Account[0].showTwoFa(data)
            })
        },
        
        async checkAuthCode(pin) {
            await this.callAuthenticatorCheck(pin).then(async () => {                
                this.settings = await this.loadSettings()
                this.$refs.Account[0].hideTwoFa()
            }).catch(async () => {
                this.$refs.Account[0].showTwoFaError()
            })
        },
        okSuccess() {
            this.$refs['success-modal'].hide()
            setTimeout(() => {
            this.$router.push(this.$i18nRoute({ name: 'Dashboard' }))
            this.logout()
      }, 2000)
        },

        okFail() {
            this.$refs['fail-modal'].hide()
        }
    },
}
</script>

<style>

</style>