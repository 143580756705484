<template>
    <div>
        <SettingsForm ref="form"
            :tKey="tKey"
            :base="settings"
            :form="form"
            :formAttributes="formAttributes"
            :validation="validations"
        >
            <b-button variant="success" @click="save">
                <font-awesome-icon class="mr-2" icon="save" />
                <span v-html="$t(tKey + 'save')" />
            </b-button>
        </SettingsForm>
    </div>
</template>

<script>
import { required, maxLength } from 'vuelidate/lib/validators'
import SettingsForm from '@/components/settings/SettingsForm'
export default {
    name: 'ProfileSettings',
    components: {
        SettingsForm,
    },
    props: {
        settings: { type: Object, required: true },
    },
    data() {
        return {
            tKey: 'settings.Profile.',
            form: null,
        }
    },
    mounted() {        
        this.form = { ...this.settings }
    },
    computed: {
        formAttributes() {
            return [
                {
                    key: 'FirstName',
                    type: 'text',
                    validation: ['required', 'maxLength'],
                },
                {
                    key: 'LastName',
                    type: 'text',
                    validation: ['required', 'maxLength'],
                },
                {
                    key: 'PhoneNumber',
                    type: 'text',
                    isConfirmed: this.settings.PhoneNumberConfirmed,
                    validation: ['phone'],
                },
            ]
        },
        validations() {
            return {
                form: {
                    FirstName: {
                        required,
                        maxLength: maxLength(50),
                    },
                    LastName: {
                        required,
                        maxLength: maxLength(50),
                    },
                    PhoneNumber: {
                        phone: (val) => {
                          return !val 
                            || /(\b(0041|0)|\B\+41)(\s?\(0\))?(\s)?[1-9]{2}(\s)?[0-9]{3}(\s)?[0-9]{2}(\s)?[0-9]{2}\b/.test(val)  
                        }
                    },
                }
            }
        },
    },
    methods: {
        save() {
            this.$refs.form.submit(this.updateProfile)
        },
        updateProfile() {
            this.$emit('update', 'Profile', this.form)
        },
    },
}
</script>

<style>

</style>