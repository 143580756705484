<template>
    <div>
        <SettingsForm ref="form"
            :tKey="tKey"
            :base="settings"
            :form="form"
            :formAttributes="formAttributes"
            :validation="validations"
        >
            <div>
                <b-button v-if="settings.TwoFactorEnabled" variant="danger" @click="disableTwoFa">
                    <span v-html="$t(tKey + 'disableTwoFa')" />
                </b-button>
                <b-button v-else variant="primary" @click="generateTwoFa">
                    <font-awesome-icon v-if="loadingTwoFa" class="mr-2" spin icon="spinner" />
                    <font-awesome-icon class="mr-2" :icon="['fab', 'google']" />
                    <span v-html="$t(tKey + 'enableTwoFa')" />
                </b-button>
            </div>
            <div class="mt-4">
                <b-button variant="success" @click="save">
                    <font-awesome-icon class="mr-2" icon="save" />
                    <span v-html="$t(tKey + 'save')" />
                </b-button>
            </div>
        </SettingsForm>
        <EnableAuthenticatorModal ref="twoFaModal" :tKey="`${tKey}twoFa.`" @checkAuthCode="(pin) => $emit('checkAuthCode', pin)" />
    </div>
</template>

<script>
import { required, maxLength, email } from 'vuelidate/lib/validators'
import SettingsForm from '@/components/settings/SettingsForm'
import EnableAuthenticatorModal from '@/components/settings/EnableAuthenticatorModal'
export default {
    name: 'AccountSettings',
    components: {
        SettingsForm,
        EnableAuthenticatorModal,
    },
    props: {
        settings: { type: Object, required: true },
    },
    data() {
        return {
            tKey: 'settings.Account.',
            form: null,
            loadingTwoFa: false,
        }
    },
    computed: {
        formAttributes() {
            return [
                {
                    key: 'Email',
                    type: 'text',
                    isConfirmed: this.settings.EmailConfirmed,
                    validation: ['required', 'email'],
                },
                {
                    key: 'UserName',
                    type: 'text',
                    validation: ['required', 'maxLength'],
                    help: true,
                },
            ]
        },
        validations() {
            return {
                form: {
                    Email: {
                        required,
                        email,
                    },
                    UserName: {
                        required,
                        maxLength: maxLength(50),
                    },
                }
            }
        },
    },
    mounted() {        
        this.form = { ...this.settings }
    },
    methods: {
        save() {
            this.$refs.form.submit(this.updateProfile)
        },
        generateTwoFa() {
            this.loadingTwoFa = true
            this.$emit('generateTwoFa')
        },
        showTwoFa(data) {
            this.loadingTwoFa = false
            this.$refs.twoFaModal.show(data)
        },
        hideTwoFa() {
            this.$refs.twoFaModal.hide()
        },
        disableTwoFa() {
            this.form.TwoFactorEnabled = false
            this.$emit('update', 'Account', this.form)
        },
        showTwoFaError() {
            this.$refs.twoFaModal.showError()
        },
        updateProfile() {            
            this.$emit('update', 'Account', this.form)
        },
    },
}
</script>

<style>

</style>